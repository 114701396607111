import { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProgressBar from 'react-bootstrap/ProgressBar'

import Web3 from 'web3';
import { ethers } from "ethers";
import './App.css';

const NFT_CONTRACT_ADDRESS = "0xbbFd84163913D1386aB582FBF2D52A232b9BAF40";
const GAME_CONTRACT_ADDRESS = "0xccD2E64BBc382e694d8cbf75eb0A9cC3490B6D13";
const GAME_CONTRACT_ABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_nftContractAddress",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "player",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Claim",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "Paused",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "player",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "playerId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "PlayerJoined",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "player",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bytes32",
        "name": "choice",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Reveal",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "player1",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "player2",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bytes32",
        "name": "choice",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Tie",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "Unpaused",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "winner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "loser",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bytes32",
        "name": "choice",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Winner",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "player",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "Withdrawn",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "gameId",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "games",
    "outputs": [
      {
        "internalType": "bool",
        "name": "started",
        "type": "bool"
      },
      {
        "internalType": "bool",
        "name": "finished",
        "type": "bool"
      },
      {
        "internalType": "bool",
        "name": "withdrawn",
        "type": "bool"
      },
      {
        "internalType": "bool",
        "name": "tie",
        "type": "bool"
      },
      {
        "internalType": "bool",
        "name": "claimed",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "withdrawalTime",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address payable",
            "name": "playerAddress",
            "type": "address"
          },
          {
            "internalType": "bytes32",
            "name": "playerHash",
            "type": "bytes32"
          },
          {
            "internalType": "bytes1",
            "name": "playerRevealed",
            "type": "bytes1"
          },
          {
            "internalType": "bytes1",
            "name": "choice",
            "type": "bytes1"
          },
          {
            "internalType": "bool",
            "name": "claimedTie",
            "type": "bool"
          }
        ],
        "internalType": "struct RockPaperScissors.Player",
        "name": "player1",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "address payable",
            "name": "playerAddress",
            "type": "address"
          },
          {
            "internalType": "bytes32",
            "name": "playerHash",
            "type": "bytes32"
          },
          {
            "internalType": "bytes1",
            "name": "playerRevealed",
            "type": "bytes1"
          },
          {
            "internalType": "bytes1",
            "name": "choice",
            "type": "bytes1"
          },
          {
            "internalType": "bool",
            "name": "claimedTie",
            "type": "bool"
          }
        ],
        "internalType": "struct RockPaperScissors.Player",
        "name": "player2",
        "type": "tuple"
      },
      {
        "internalType": "address payable",
        "name": "winnerAddress",
        "type": "address"
      },
      {
        "internalType": "address payable",
        "name": "loserAddress",
        "type": "address"
      },
      {
        "internalType": "bytes32",
        "name": "winnerChoice",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "tieChoice",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "maxWaitTime",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "nftContract",
    "outputs": [
      {
        "internalType": "contract RPSHand",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "nftContractAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "ownerAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "paper",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "paused",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "playerGames",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "playingPrice",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "rock",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "scissors",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [],
    "name": "totalMaticMoved",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "playerHash",
        "type": "bytes32"
      }
    ],
    "name": "joinGame",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function",
    "payable": true
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "playerGameId",
        "type": "uint256"
      },
      {
        "internalType": "bytes32",
        "name": "secret",
        "type": "bytes32"
      }
    ],
    "name": "revealHand",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256[]",
        "name": "playerGamesId",
        "type": "uint256[]"
      }
    ],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "playerAddress",
        "type": "address"
      }
    ],
    "name": "getPlayerGames",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "id",
        "type": "uint256"
      }
    ],
    "name": "getGameInfo",
    "outputs": [
      {
        "components": [
          {
            "internalType": "bool",
            "name": "started",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "finished",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "withdrawn",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "tie",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "claimed",
            "type": "bool"
          },
          {
            "internalType": "uint256",
            "name": "withdrawalTime",
            "type": "uint256"
          },
          {
            "components": [
              {
                "internalType": "address payable",
                "name": "playerAddress",
                "type": "address"
              },
              {
                "internalType": "bytes32",
                "name": "playerHash",
                "type": "bytes32"
              },
              {
                "internalType": "bytes1",
                "name": "playerRevealed",
                "type": "bytes1"
              },
              {
                "internalType": "bytes1",
                "name": "choice",
                "type": "bytes1"
              },
              {
                "internalType": "bool",
                "name": "claimedTie",
                "type": "bool"
              }
            ],
            "internalType": "struct RockPaperScissors.Player",
            "name": "player1",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "address payable",
                "name": "playerAddress",
                "type": "address"
              },
              {
                "internalType": "bytes32",
                "name": "playerHash",
                "type": "bytes32"
              },
              {
                "internalType": "bytes1",
                "name": "playerRevealed",
                "type": "bytes1"
              },
              {
                "internalType": "bytes1",
                "name": "choice",
                "type": "bytes1"
              },
              {
                "internalType": "bool",
                "name": "claimedTie",
                "type": "bool"
              }
            ],
            "internalType": "struct RockPaperScissors.Player",
            "name": "player2",
            "type": "tuple"
          },
          {
            "internalType": "address payable",
            "name": "winnerAddress",
            "type": "address"
          },
          {
            "internalType": "address payable",
            "name": "loserAddress",
            "type": "address"
          },
          {
            "internalType": "bytes32",
            "name": "winnerChoice",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32",
            "name": "tieChoice",
            "type": "bytes32"
          }
        ],
        "internalType": "struct RockPaperScissors.GameInfo",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      }
    ],
    "name": "setPrice",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];


const nodeKey = "https://polygon-mumbai.g.alchemy.com/v2/qhfaGao4qPuLRPUrY5Wu-yy2wXYWs9ut";

function App() {

  // web3 and contract should be shared, not itialized on every call
  var metamaskConnection;
  var web3;
  var contract;
  (window.ethereum ? metamaskConnection = "Installed" : metamaskConnection = "Not installed");

  const [user, setUser] = useState({ account: "Disconnected", balance: "Disconnected" });
  const [choice, setChoice] = useState(null);

  // Tables
  const [revealTable, setRevealTable] = useState();
  const [claimTable, setClaimTable] = useState();
  const [gamesTable, setGamesTable] = useState();

  // Modal
  const [showJoiningGame, setShowJoiningGame] = useState(false);
  const handleJoiningGameClose = () => setShowJoiningGame(false);

  // OffCanvas
  const [contractStats, setContractStats] = useState()
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const handleCloseOffCanvas = () => {
    setShowOffCanvas(false);
  }
  const handleShowOffCanvas = () => {
    setShowOffCanvas(true);
  }

  const [playerHistory, setPlayerHistory] = useState()
  const [showHistoryOffCanvas, setShowHistoryOffCanvas] = useState(false);
  const handleCloseHistoryOffCanvas = () => {
    setShowHistoryOffCanvas(false);
  }
  const handleShowHistoryOffCanvas = () => {
    setShowHistoryOffCanvas(true);
  }

  // Play tx
  const [playTxState, setPlayTxState] = useState();
  const [playTxMsg, setPlayTxMsg] = useState(
    <div>
      Please wait until transaction is successully mined.
      <div className='text-center mt-2'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );

  // Game UI
  const [playerHistoryButton, setPlayerHistoryButton] = useState();
  const [connectWalletButton, setConnectWalletButton] = useState(
    <div>
      <Button
        className="my-1"
        variant="primary"
        onClick={() => { connectWallet() }}
      >
        Connect Wallet
      </Button>
    </div>
  );
  const [gameUI, setGameUI] = useState();


  async function handleMetamask() {

    // User doesn't have metamask
    if (!window.ethereum) {
      // Mainnet -> "https://speedy-nodes-nyc.moralis.io/266f6e2668625246a335f50c/polygon/mainnet"
      // Testnet -> nodeKey 

      web3 = new Web3(nodeKey);
      contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);
      return;
    }

    // User has metamask  
    web3 = new Web3(window.ethereum);
    contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);

    // User has metamask
    window.ethereum.on('accountsChanged', async (accounts) => {

      if (accounts.length === 0) {
        web3 = new Web3(nodeKey);
        setUser({ account: "Disconnected", balance: "Disconnected" });

        contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);
        return;
      }

      web3 = new Web3(window.ethereum);

      let userBal = await web3.eth.getBalance(accounts[0]);
      let bal = web3.utils.fromWei(userBal);
      setUser({ account: accounts[0], balance: bal, connected: true });

      contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);
    });

    window.ethereum.on('connect', async (info) => {
      web3 = new Web3(window.ethereum);

      let account = await window.ethereum.request({ method: 'eth_accounts' })
      if (account.length > 0) {
        let bal = web3.utils.fromWei(await web3.eth.getBalance(account[0]));
        setUser({ account: account[0], balance: bal, connected: true });

        contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);
      }
    });

    window.ethereum.on('disconnect', async () => {
      web3 = new Web3(nodeKey);

      setUser({ account: "Disconnected", balance: "Disconnected" });

      contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);
    });


  }

  async function connectWallet() {

    if (!window.ethereum) {
      alert("Metamask is not installed");
      return;
    }

    web3 = new Web3(window.ethereum);

    let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    let bal = web3.utils.fromWei(await web3.eth.getBalance(accounts[0]));
    setUser({ account: accounts[0], balance: bal, connected: true });

    contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);
  }

  async function getStats(playerGames) {

    web3 = new Web3(nodeKey);
    contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);

    let playingPrice = await contract.methods.playingPrice().call()

    var allEvents;
    try {
      allEvents = await contract.getPastEvents(
        'allEvents',
        { fromBlock: 1 }
      );
    }
    catch (err) {
      getStats()
      return;
    }

    let totalGames = 0;
    let totalGamesJoined = 0;
    let totalMaticBet = 0
    let totalFeeAmount = 0;
    let totalGamesWon = 0;
    let totalGamesTied = 0;
    let totalGamesClaimed = 0;

    let amountRock = 0;
    let amountPaper = 0;
    let amountScissors = 0;
    let totalAmountChoices = 0;

    for (let event of allEvents) {
      if (event.event === "PlayerJoined") totalGamesJoined += 1;
      if (event.event === "Winner") {
        let choice = web3.utils.toAscii(event.returnValues.choice);
        if (parseInt(choice) === 1) amountRock += 1;
        if (parseInt(choice) === 2) amountPaper += 1;
        if (parseInt(choice) === 3) amountScissors += 1;

        totalGamesWon += 1;
      }
      if (event.event === "Tie") {
        let choice = web3.utils.hexToAscii(event.returnValues.choice);
        if (parseInt(choice) === 1) amountRock += 2;
        if (parseInt(choice) === 2) amountPaper += 2;
        if (parseInt(choice) === 3) amountScissors += 2;

        totalGamesTied += 1;
      }
      if (event.event === "Claim") totalGamesClaimed += 1;

    }

    totalGames = Math.floor(totalGamesJoined / 2);
    totalMaticBet = web3.utils.fromWei((totalGamesJoined * playingPrice).toString());
    totalFeeAmount = web3.utils.fromWei((totalGamesClaimed * (playingPrice * 0.025)).toString());
    totalAmountChoices = amountRock + amountPaper + amountScissors;
    let rockPercentage = Math.floor(amountRock / totalAmountChoices * 100);
    let paperPercentage = Math.floor(amountPaper / totalAmountChoices * 100);
    let scissorsPercentage = Math.floor(amountScissors / totalAmountChoices * 100);

    let element =
      <div>

        <h4>
          Game stats:
        </h4>

        <div>
          Total games : {totalGames}
        </div>
        <div>
          Total games Won : {totalGamesWon}
        </div>
        <div>
          Total games Tied : {totalGamesTied}
        </div>
        <div>
          Total matic bet : {totalMaticBet} MATIC
        </div>
        <div>
          Total Fee amount : {totalFeeAmount} MATIC
        </div>

        <div>
          Wins with rock : <ProgressBar now={rockPercentage} label={`${rockPercentage}%`} />
        </div>
        <div>
          Wins with paper : <ProgressBar now={paperPercentage} label={`${paperPercentage}%`} />
        </div>
        <div>
          Wins with scissors : <ProgressBar now={scissorsPercentage} label={`${scissorsPercentage}%`} />
        </div>


      </div>;

    setContractStats(element);

  }

  async function getPlayerHistory(playerGames) {

    // Get stats
    let gamesWon = 0;
    let gamesLost = 0;
    let gamesTied = 0;
    let chosenRock = 0; // "0x31"
    let chosenPaper = 0; // "0x32"
    let chosenScissors = 0; // "0x33"

    for (let game of playerGames) {
      let gameInfo = game.info;

      if (user.account.toLowerCase() === gameInfo.winnerAddress.toLowerCase()) {
        gamesWon += 1;
      }
      if (user.account.toLowerCase() === gameInfo.loserAddress.toLowerCase()) {
        gamesLost += 1;
      }
      if (gameInfo.tie) {
        gamesTied += 1;
      }

      if (user.account.toLowerCase() === gameInfo.player1.playerAddress.toLowerCase()) {
        if (gameInfo.player1.choice === "0x31") chosenRock += 1
        else if (gameInfo.player1.choice === "0x32") chosenPaper += 1
        else if (gameInfo.player1.choice === "0x33") chosenScissors += 1
      }
      else if (user.account.toLowerCase() === gameInfo.player2.playerAddress.toLowerCase()) {
        if (gameInfo.player2.choice === "0x31") chosenRock += 1
        else if (gameInfo.player2.choice === "0x32") chosenPaper += 1
        else if (gameInfo.player2.choice === "0x33") chosenScissors += 1
      }

    }

    let element =
      <div>

        <h4>
          Game stats:
        </h4>

        <div>
          Joined: {playerGames.length}
        </div>
        <div>
          Won : {gamesWon}
        </div>
        <div>
          Lost : {gamesLost}
        </div>
        <div>
          Tied : {gamesTied}
        </div>

        <div>
          Chosen rock: {chosenRock}
        </div>
        <div>
          Chosen paper: {chosenPaper}
        </div>
        <div>
          Chosen scissors: {chosenScissors}
        </div>


      </div>;

    setPlayerHistory(element)
  }

  async function getRecentGames() {

    // User has metamask  
    web3 = new Web3(nodeKey);

    contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);

    var contractEvents;
    try {
      contractEvents = await contract.getPastEvents(
        'allEvents',
        { fromBlock: 1 }
      );
    }
    catch (err) {
      getRecentGames()
      return;
    }

    let rows = [];

    for (let i = 0; i < contractEvents.length; i++) {
      if (contractEvents[i].event === "Winner") {
        // Info
        let winner = contractEvents[i].returnValues.winner;
        let loser = contractEvents[i].returnValues.loser;
        let choice = web3.utils.toAscii(contractEvents[i].returnValues.choice);
        if (parseInt(choice) === 1) choice = "rock";
        else if (parseInt(choice) === 2) choice = "paper";
        else if (parseInt(choice) === 3) choice = "scissors";

        // Time left
        let gameDate = new Date(contractEvents[i].returnValues.timestamp * 1000);
        let diff = ((new Date()).getTime() - gameDate.getTime()) / (1000) / 60;
        let finalTime;
        finalTime = (Math.round(diff)) + " minutes";

        rows.push(
          <tr key={`gamesTr1-${i}`}>
            <td key={`gamesTd1-${i}`}>{winner.substring(0, 5) + "..."} has beaten {loser.substring(0, 5) + "..."} with {choice} {finalTime} minutes ago!</td>
          </tr>
        )
      }
      else if (contractEvents[i].event === "Tie") {
        let player1 = contractEvents[i].returnValues.player1;
        let player2 = contractEvents[i].returnValues.player2;

        rows.push(
          <tr key={`gamesTr1-${i}`}>
            <td key={`gamesTd1-${i}`}>{player1.substring(0, 5) + "..."} has tied against {player2.substring(0, 5) + "..."}!</td>
          </tr>
        )
      }
    }

    let table =
      <div className='d-flex justify-content-center'>
        <Table striped bordered hover variant="dark" className="initialism w-50 ">
          <tbody>
            {rows}
          </tbody>
        </Table>
      </div>;

    setGamesTable(table);
  }

  async function getRevealTable(playerGames) {

    // Reveal table
    let rows = [];
    rows.push(
      <tr key="revealTr">
        <td key="reveaTag1">Game id</td>
        <td key="revealTag2">Reveal</td>
        <td key="revealTag3">Revealed</td>
        <td key="revealTag4">Time left (withdraw)</td>
      </tr>
    )

    for (let game of playerGames) {
      let id = game.id;
      let gameInfo = game.info;

      // Reveal
      let button;
      let player1Joined = web3.utils.toAscii(gameInfo.player1.playerHash) !== "\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00"
      let player2Joined = web3.utils.toAscii(gameInfo.player2.playerHash) !== "\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00";

      let playerRevealed;
      let opponentRevealed;
      if ((gameInfo.player1.playerAddress).toLowerCase() === user.account) {
        playerRevealed = web3.utils.toAscii(gameInfo.player1.playerRevealed);
        opponentRevealed = web3.utils.toAscii(gameInfo.player2.playerRevealed);
      }
      else if ((gameInfo.player2.playerAddress).toLowerCase() === user.account) {
        playerRevealed = web3.utils.toAscii(gameInfo.player2.playerRevealed);
        opponentRevealed = web3.utils.toAscii(gameInfo.player1.playerRevealed);
      }
      if (playerRevealed !== "Y") { playerRevealed = "❌"; } else { playerRevealed = "✅" }
      if (opponentRevealed !== "Y") { opponentRevealed = "❌"; } else { opponentRevealed = "✅" }

      // Time left
      let gameDate = new Date(gameInfo.withdrawalTime * 1000);
      let diff = (gameDate.getTime() - (new Date()).getTime()) / (1000) / 60;
      let finalTime;
      if (diff > 0) finalTime = (Math.round(diff)) + " minutes";

      // Check withdraw state
      if (playerRevealed === "✅" && opponentRevealed === "✅") {
        finalTime = "N/A";
      }
      if (gameInfo.claimed) {
        finalTime = "Game has been claimed";
      }
      if (gameInfo.tie) {
        finalTime = "Game result is a tie";
      }
      if (gameInfo.withdrawn) {
        finalTime = "Game has been withdrawn";
      }

      if (diff <= 0) {
        if ((gameInfo.player1.playerAddress).toLowerCase() === user.account && opponentRevealed === "❌") {
          if (gameInfo.withdrawn) {
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">You have already withdrawn</Tooltip>}
            >
              {({ ref, ...triggerHandler }) => (
                <span
                  className="0"
                  ref={ref}
                  {...triggerHandler}
                >
                  <Button
                    variant="primary"
                    disabled
                  >
                    Withdraw
                  </Button>
                </span>

              )}
            </OverlayTrigger>
          }
          else {
            finalTime =
              <Button
                variant="primary"
                onClick={() => { claim(id) }}
              >
                Withdraw
              </Button>;
          }
        }
        else if ((gameInfo.player2.playerAddress).toLowerCase() === user.account && opponentRevealed === "❌") {
          if (gameInfo.withdrawn) {
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">You have already withdrawn</Tooltip>}
            >
              {({ ref, ...triggerHandler }) => (
                <span
                  className="0"
                  ref={ref}
                  {...triggerHandler}
                >
                  <Button
                    variant="primary"
                    disabled
                  >
                    Withdraw
                  </Button>
                </span>

              )}
            </OverlayTrigger>
          }
          else {
            finalTime =
              <Button
                variant="primary"
                onClick={() => { claim(id) }}
              >
                Withdraw
              </Button>;
          }
        }
        else {
          if (gameInfo.withdrawn) finalTime = "Your opponent has withdrawn";
          else if (!gameInfo.withdrawn && !gameInfo.claimed) finalTime = "Your opponent can withdraw";
        }
      }

      if (playerRevealed === "✅") {
        button =
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">You have already revealed</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <span
                className="0"
                ref={ref}
                {...triggerHandler}
              >
                <Button
                  variant="primary"
                  disabled
                >
                  Reveal
                </Button>
              </span>

            )}
          </OverlayTrigger>
      }
      else if (player1Joined && player2Joined) {

        button =
          <Button
            variant="primary"
            onClick={
              async () => {
                let secret = localStorage.getItem((user.account + "-" + id));
                let revealReceipt = await reveal(id, secret);
                // Was sucessful
                if (revealReceipt.status) {
                  //If you want to delete localStorage -> localStorage.removeItem((user.account + "-" + i));
                }
              }
            }
          >
            Reveal
          </Button>;
      }
      else {
        button =
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">An opponent hasn't joined yet</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <span
                className="0"
                ref={ref}
                {...triggerHandler}
              >
                <Button
                  variant="primary"
                  disabled
                >
                  Reveal
                </Button>
              </span>
            )}
          </OverlayTrigger>
      }

      rows.push(
        <tr key={`revealTr1-${id}`}>
          <td key={`revealTd1-${id}`}>{id}</td>
          <td key={`revealTd2-${id}`}>{button}</td>
          <td key={`revealTd3-${id}`}> [You: {playerRevealed}] [Opponent: {opponentRevealed}]</td>
          <td key={`revealTd4-${id}`}> {finalTime} </td>
        </tr>
      )
    }

    let table = <Table striped bordered hover variant="dark" className="initialism">
      <tbody>
        {rows}
      </tbody>
    </Table>;

    let element =
      <Col>
        <h2 className="mt-3 ">
          REVEAL HAND
        </h2>
        {table}
      </Col>

    setRevealTable(element);
  }

  async function getClaimTable(playerGames) {

    // Claim Table
    let rows = [];
    rows.push(
      <tr key="claimTr1">
        <td key="claimTd1">Game id</td>
        <td key="claimTd2">Claim</td>
        <td key="claimTd3">Claimed</td>
        <td key="claimTd4">Result</td>
      </tr>
    )

    for (let game of playerGames) {
      let id = game.id;
      let gameInfo = game.info;

      let withdrawAble = false;
      let nowDate = new Date();
      let withdrawalTime = new Date(gameInfo.withdrawalTime * 1000);

      if (
        gameInfo.player1.playerRevealed === "Y" &&
        gameInfo.player2.playerRevealed === "N" &&
        withdrawalTime <= nowDate &&
        user.account.toLowerCase() === gameInfo.player1.playerAddress
      ) {
        withdrawAble = true;
      } else if (
        gameInfo.player1.playerRevealed === "N" &&
        gameInfo.player2.playerRevealed === "Y" &&
        withdrawalTime <= nowDate &&
        user.account.toLowerCase() === gameInfo.player2.playerAddress
      ) {
        withdrawAble = true;
      }

      let claimed;
      (gameInfo.claimed ? claimed = "Has been claimed" : claimed = "Not yet")
      if (gameInfo.player1.playerAddress.toLowerCase() === user.account && gameInfo.player1.claimedTie) {
        claimed = "Has been claimed";
      }
      else if (gameInfo.player2.playerAddress.toLowerCase() === user.account && gameInfo.player2.claimedTie) {
        claimed = "Has been claimed";
      }

      let result;
      if (gameInfo.tie) {
        result = "Tie";
      }
      if (gameInfo.winnerAddress.toLowerCase() === user.account) {
        result = "Won the game";
      }

      let button;
      if (gameInfo.claimed || claimed === "Has been claimed" || gameInfo.withdrawn) {
        button =
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">Game has been claimed</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <span
                className="0"
                ref={ref}
                {...triggerHandler}
              >
                <Button
                  variant="primary"
                  disabled
                >
                  Claim
                </Button>
              </span>
            )}
          </OverlayTrigger>
      }
      else {
        button =
          <Button
            variant="primary"
            onClick={() => { claim(id) }}
          >
            Claim
          </Button>
      }

      if (gameInfo.winnerAddress.toLowerCase() === user.account || withdrawAble || gameInfo.tie) {
        rows.push(
          <tr key={`claimTr1-${id}`}>
            <td key={`claimTd1-${id}`}> {id} </td>
            <td key={`claimTd2-${id}`}> {button} </td >
            <td key={`claimTd3-${id}`}> {claimed} </td>
            <td key={`claimTd4-${id}`}> {result} </td>
          </tr>
        )
      }

    }

    let table = <Table striped bordered hover variant="dark" className="initialism">
      <tbody>
        {rows}
      </tbody>
    </Table>;

    let element =
      <Col>
        <h2 className="mt-3 ">
          CLAIM TABLE
        </h2>
        {table}
      </Col>

    setClaimTable(element);

  }

  async function play() {

    // Check that the player can join TODO better ui

    if (!window.ethereum) {
      alert("Metamask is not installed");
      return;
    }

    if (!user.connected) {
      alert("You haven't connected an account");
      return;
    }

    if (!choice) {
      alert("You haven't chosen a hand");
      return;
    }

    // Show modal
    setShowJoiningGame(true);

    // Get web3
    web3 = new Web3(window.ethereum);
    contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);

    // Generate random secret
    let random = Math.floor(Math.random() * 10 ** 28);
    let playerSecret = choice + "-" + random.toString();
    playerSecret = ethers.utils.formatBytes32String(playerSecret);
    let playerHash = ethers.utils.keccak256(playerSecret);

    // Generate temporal id
    let today = new Date();
    let yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    let timestamp = dd + '/' + mm + '/' + yyyy;

    // Get essential information for smart contract interaction TODO unhandled error if .call() or estimateGas() fails
    let playingPrice = await contract.methods.playingPrice().call();
    let estimatedGas = await contract.methods.joinGame(playerHash).estimateGas({ from: user.account, value: playingPrice });

    // Save in case browser reloads
    localStorage.setItem((user.account + "-" + timestamp), playerSecret);

    // Join game
    let joinGameReceipt;
    try {
      joinGameReceipt = await contract.methods.joinGame(playerHash).send({ from: user.account, value: playingPrice, gas: estimatedGas });
    }
    catch (err) {
      setPlayTxState("Failed");
    }

    let gameId;
    if (joinGameReceipt) {
      setPlayTxState("Successful");
      gameId = joinGameReceipt.events.PlayerJoined.returnValues.gameId;
      // Save choice for future claim
      localStorage.setItem((user.account + "-" + gameId), playerSecret);
    }
    localStorage.removeItem((user.account + "-" + timestamp))

    // Joined game
    setChoice(null);
  }

  async function reveal(gameId, playerSecret) {
    web3 = new Web3(window.ethereum);
    contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);

    // Reveal hand
    let estimatedGas = await contract.methods.revealHand(gameId, playerSecret).estimateGas({ from: user.account });
    let revealHandReceipt = await contract.methods.revealHand(gameId, playerSecret).send({ from: user.account, gas: estimatedGas });
    return (revealHandReceipt)
  }

  async function claim(gameId) {
    web3 = new Web3(window.ethereum);
    contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);

    // Get essential information for smart contract interaction TODO unhandled error if .call() or estimateGas() fails
    let estimatedGas = await contract.methods.claim([gameId]).estimateGas({ from: user.account });

    // Claim
    let claimReceipt;
    try {
      claimReceipt = await contract.methods.claim([gameId]).send({ from: user.account, gas: estimatedGas });
    }
    catch (err) {
      alert("Claim failed, try again.")
    }

    // Was sucessful
    if (claimReceipt) {
    }

  }

  // Handle metamask
  useEffect(() => {
    handleMetamask();
    getRecentGames(); // this creates the metamask non-200 status code '400'
    getStats();
    console.log(choice)
  }, []);

  // Render game UI
  useEffect(() => {

    if (user.connected) {

      setConnectWalletButton();
      setPlayerHistoryButton(
        <Button
          className="my-1"
          variant="primary"
          onClick={() => { handleShowHistoryOffCanvas() }}
        >
          Your history
        </Button>);
    }

  }, [user]);

  // Render tables
  useEffect(() => {

    // TODO sometimes gets called, sometimes doesn't
    async function getUserTables() {
      web3 = new Web3(window.ethereum);
      contract = new web3.eth.Contract(GAME_CONTRACT_ABI, GAME_CONTRACT_ADDRESS);
      let playerGames = [];

      // Fetch player games info
      let playerGamesId = await contract.methods.getPlayerGames(user.account).call();
      for (let i of playerGamesId) {
        let gameInfo = await contract.methods.getGameInfo(i).call();
        playerGames.push({
          id: i,
          info: gameInfo
        })
      }

      // Get tables
      getRevealTable(playerGames);
      getClaimTable(playerGames);
      // getStats(playerGames);  
      getPlayerHistory(playerGames);
    }

    if (user.connected) {
      getUserTables();
    }

  }, [claimTable, gamesTable, revealTable]);


  // Handle transaction result
  useEffect(() => {
    if (playTxState === "Failed") {
      setPlayTxMsg(
        <div>
          Transaction has failed
        </div>
      );
    }

    if (playTxState === "Successful") {
      setPlayTxMsg(
        <div>
          Transaction has been successful!
        </div>
      );
      getRevealTable();
      getClaimTable();
    }
  }, [playTxState]);

  // Handle Modal messages
  useEffect(() => {

    // If modal closed reset to default msg
    if (!showJoiningGame) {
      setPlayTxMsg(
        <div>
          Please wait until transaction is successully mined.
          <div className='text-center mt-2'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      );
    };

  }, [showJoiningGame]);

  return (
    <div className="body">

      <Container>

        <Row>
          <Col></Col>
          <Col className="my-4 text-center" >
            <h1>
              Rock paper scissors
            </h1>
          </Col>
          <Col></Col>
        </Row>

        <div>
          <a target="_blank" rel="noreferrer" href={"https://mumbai.polygonscan.com/address/" + GAME_CONTRACT_ADDRESS}>
            Game contract
          </a>
        </div>
        <div>
          <a target="_blank" rel="noreferrer" href={"https://mumbai.polygonscan.com/address/" + NFT_CONTRACT_ADDRESS}>
            NFT contract
          </a>
        </div>
        <div>
          <a target="_blank" rel="noreferrer" href="https://testnets.opensea.io/collection/rpshand-v2">
            Open sea testnet
          </a>
        </div>

        <div>
          <Button
            className="my-1"
            variant="primary"
            onClick={() => { handleShowOffCanvas() }}
          >
            Stats
          </Button>
        </div>

        <div>Metamask : {metamaskConnection}</div>
        <div>Account : {user.account} </div>
        <div className="mb-3 "> Matic : {user.balance} </div>
        <div>{playerHistoryButton}</div>

        <div className="my-2 text-center">
          {connectWalletButton}
          
        <Row >
          <Col >
            <h2>
              JOIN GAME
            </h2>

            <div className="w-50 mx-auto">
              <CardGroup>
                <Card>
                  <Card.Img variant="top" src="/Options.jpeg" />
                  <Card.Footer>
                    <Button
                      className="mx-1"
                      variant="primary"
                      onClick={() => { setChoice("1")} }
                    >
                      Rock
                    </Button>
                  </Card.Footer>
                </Card>
                <Card>
                  <Card.Img variant="top" src="/Options.jpeg" />
                  <Card.Footer>
                    <Button
                      className="mx-1"
                      variant="primary"
                      onClick={() => { setChoice("2") }}
                    >
                      Paper
                    </Button>
                  </Card.Footer>
                </Card>
                <Card>
                  <Card.Img variant="top" src="/Options.jpeg" />
                  <Card.Footer>
                    <Button
                      className="mx-1"
                      variant="primary"
                      onClick={() => { setChoice("3") }}
                    >
                      Scissors
                    </Button>
                  </Card.Footer>
                </Card>
              </CardGroup>
            </div>
            <div>
              <Button
                variant="primary"
                className="my-2"
                onClick={() => { play() }}
              >
                Play
              </Button>
            </div>
          </Col>

        </Row>
          <Row className="my-2 text-center">
            {revealTable}
            {claimTable}
          </Row>
        </div>


        <Row>
          <Col className="my-2 text-center" >
            <h2 >
              RECENT PLAYS
            </h2>

            {gamesTable}

          </Col>
        </Row>

      </Container>

      <Modal show={showJoiningGame} onHide={handleJoiningGameClose}>
        <Modal.Header closeButton>
          <Modal.Title>Joining game...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {playTxMsg}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleJoiningGameClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas show={showOffCanvas} onHide={handleCloseOffCanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><h3>Statistics</h3></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {contractStats}
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showHistoryOffCanvas} onHide={handleCloseHistoryOffCanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><h3>Player History</h3></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {playerHistory}
        </Offcanvas.Body>
      </Offcanvas>

    </div>
  );
}

export default App;
